import React from 'react';
import config from '../../config';

import sens from '../assets/images/sens.png';

export default function Header() {
  return (
    <div id="header">
        <img src={sens} alt="" />
    </div>
  );
}
