import React from 'react';
import ContactForm from './ContactForm';
import config from '../../config';
export default function Footer() {
  return (
    <div id="footer">
      <div className="container medium">
        <header className="major last">
          <h2>Contact</h2>
        </header>

        <ContactForm />

        <ul class="contact">
          <li><a href="tel:+32498883085">+32 498 88 30 85</a></li>
          <li>Vleugelarendijk 36, 2220 Heist-op-den-Berg</li>
          <li><a href="mailto:info@senselectrity.be">info@senselectrity.be</a></li>
          <li>BE0743.386.818</li>
        </ul>

        <ul className="copyright">
          <li>&copy; Sens Electricity
          </li>
        </ul>
      </div>
    </div>
  );
}
