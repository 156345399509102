import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>
          kandidaat voor werk van elk formaat
          <br />
          met veel of weinig draad
        </h2>
      </header>
      <div className="box alt container">
        <section className="feature left">
          <Link to="/jarenlange-ervaring" className="image icon fa-solid fa-trophy">
            <img src={pic1} alt="" />
          </Link>
          <div className="content">
            <h3>Ervaring</h3>
            <p>
              Na mijn opleiding elektrotechniek ben ik als elektricien aan de slag gegaan bij een bedrijf waar ik tien jaar lang instond voor villaprojecten. Dit hield in: bekabeling, kastenbouw, programmering en afwerking. In 2020 vond ik de tijd rijp om een eigen bedrijf op te richten: SENS Electricity.
            </p>
          </div>
        </section>
        <section className="feature right">
          <Link to="/nieuwbouw-en-renovaties" className="image icon fa-solid fa-house">
            <img src={pic2} alt="" />
          </Link>
          <div className="content">
            <h3>Nieuwbouw en renovaties</h3>
            <p>
              U kunt bij ons terecht voor nieuwbouw, met of zonder domotica, en renovatie.
            </p>
          </div>
        </section>
        <section className="feature left">
          <Link to="/klusjes" className="image icon fa-solid fa-bolt">
            <img src={pic3} alt="" />
          </Link>
          <div className="content">
            <h3>Kleine klusjes</h3>
            <p>
              Heb je stopcontacten of verlichting die niet meer werkt? Zoek je iemand voor een herkeuring, verzwaring, aansluiten van een poolhouse, …?
              Geen enkele klus is voor ons te klein.
            </p>
          </div>
        </section>
        <section className="feature right">
          <Link to="/laadpalen" className="image icon fa-solid fa-charging-station">
            <img src={pic3} alt="" />
          </Link>
          <div className="content">
            <h3>Laadpalen</h3>
            <p>
              Meer en meer mensen stappen over naar elektrisch rijden. Hiervoor is het noodzakelijk om thuis een laadsysteem te installeren. Wij bekijken samen met u wat er nodig is om uw elektrische installatie hieraan aan te passen en welke laadoplossing het best bij u past.
            </p>
          </div>
        </section>
      </div>

    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
