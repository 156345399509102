module.exports = {
  siteTitle: 'Sens Electricity',
  manifestName: 'Sens Electricity',
  manifestShortName: 'Sens',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#000000',
  manifestThemeColor: '#000000',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`,
};
